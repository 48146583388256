const loginBackground = require("../assets/images/login-background.jpg");
const loginBackgroundjpeg = require("../assets/images/login-background.jpeg");
const logoLight = require("../assets/logos/logo-light.svg");
const logoDark = require("../assets/logos/logo-dark.svg");
const symbLight = require("../assets/logos/symb-light.svg");
const symbDark = require("../assets/logos/symb-dark.svg");
const symbLightBlue = require("../assets/logos/symb_lightBlue.svg");

const carrossel1 = require("../assets/images/img-carrossel-1.jpg");
const carrossel2 = require("../assets/images/img-carrossel-2.jpg");
const carrossel3 = require("../assets/images/img-carrossel-3.jpg");
const carrossel4 = require("../assets/images/img-carrossel-4.jpg");
const carrossel5 = require("../assets/images/img-carrossel-5.jpg");
const carrossel6 = require("../assets/images/img-carrossel-6.jpg");
const playStore = require("../assets/images/google_play.svg");
const appleStore = require("../assets/images/app_store.svg");
const playStoreBlack = require("../assets/images/google_playBlack.svg");
const appleStoreBlack = require("../assets/images/app_storeBlack.svg");
const QRCode = require("../assets/images/qrcode-button.svg");
const QRCodeNoText = require("../assets/images/qrcode-button2.svg");
const AccreditoSVG = require("../assets/images/accreditosvg.svg");

const BNDESLogo = require("../assets/images/logo-bndes-300dpi.png");
const LogoOpenFinance = require("../assets/images/open-finance.png");

const BNDESLogoJPG = require("../assets/images/logo-bndes-300dpi.jpeg");

export default {
  loginBackground,
  loginBackgroundjpeg,
  playStore,
  appleStore,
  playStoreBlack,
  appleStoreBlack,
  QRCode,
  QRCodeNoText,
  carrossel1,
  carrossel2,
  carrossel3,
  carrossel4,
  carrossel5,
  carrossel6,
  //Logos
  logoLight,
  logoDark,
  symbLight,
  symbDark,
  symbLightBlue,
  AccreditoSVG,
  BNDESLogo,
  BNDESLogoJPG,
  LogoOpenFinance
};
