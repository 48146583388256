const regular = '"montserrat regular", "Helvetica", "Arial", sans-serif';
const bold = '"montserrat bold", "Helvetica", "Arial", sans-serif';
const light = '"montserrat light", "Helvetica", "Arial", sans-serif';
const semiBold = '"montserrat semibold", "Helvetica", "Arial", sans-serif';
const medium = '"montserrat medium", "Helvetica", "Arial", sans-serif';

const sizes = {
  small: "0.9rem",
  regular: "1rem",
  medium: "1.1rem",
  large: "1.3rem",
};

export default { regular, bold, semiBold, medium, light, sizes };
