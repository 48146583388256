import React, { useEffect, useState } from "react";
import { 
  isMobile,
  isIOS
} from 'react-device-detect';
import { 
  createStyles, 
} from "@mui/material";

import axios from 'axios';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { HashLoader } from "react-spinners";

import fonts from "../config/fonts";
import LogoOpenFinance from "../assets/images/open-finance.png";
import logoDark from "../assets/logos/logo-dark.svg";
import loginBackground from "../config/images";

const branchKey = process.env.REACT_APP_API_KEY;
const branchKeyLive = process.env.REACT_APP_API_KEY_LIVE;
const branchApi = axios.create({
  baseURL: 'https://api2.branch.io/v1/url',
  headers: {
    "Accept": "application/json",
    "Content-Type": 'application/json',
  }
});

export function RecepcaoOpenFinance(props) {
  const [w, setW] = useState(window.innerWidth);
  const [currentWidth, setCurrentWidth] = useState('30%');

  const styles = makeStyles();

  const urlParams = window.location.search.slice(1);

  const consentParams = {
    urlParams: window.location.href
  }

  const screenSize = window.screen.width;
  
  const createBranchLink = async () => {
    await branchApi.post(
      '/',
      {
        "branch_key": branchKeyLive,
        "channel": "openFinance",
        "feature": consentParams.urlParams,
      }
    ).then(resp => {
      console.log('resp', resp);
      if (isMobile) {
        window.location.replace(`${resp?.data?.url}`);
      }
    }).catch(err => console.log('err', err))
  } 

  useEffect(() => {
    const handleResize = () => {
      setW(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  useEffect(() => {
      createBranchLink();
      if (screenSize <= 768 && screenSize > 600) {
        setCurrentWidth('40%');
      } else if (screenSize <= 600 && screenSize > 425) {
        setCurrentWidth('50%');
      } else if (screenSize <= 425) {
        setCurrentWidth('70%');
      }
  }, [urlParams]);

  return (
      <div
        style={styles.backgroundImage}
      >
        <div
          style={{
            height: "100vh",
            width: currentWidth,
            backgroundColor: "#F9F8F8",
            left: 'auto',
            position: "absolute",
          }}
        >
            <Box
              style={{
                width: '100%',
                borderRadius: 0,
                overflow: "hidden",
                marginTop: 80,
              }}
            >
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "80%",
                    margin: "auto",
                  }}
                >
                  <img
                    src={LogoOpenFinance}
                    alt="Logo BNDES"
                    style={{ width: '45%' }}
                  />
                  <img
                    src={logoDark}
                    alt="Logo ACCREDITO"
                    style={{ width: '40%' }}
                  />
                </div> 
                {isMobile &&
                <>
                  <Box textAlign="center" color={"#888888"} style={{marginTop: 30}}>
                          Estamos validando suas informações, em instantes você será redirecionado...
                  </Box>
                  <Box width={'100%'} color={"#888888"} style={{marginTop: 30, display: 'flex', justifyContent: 'center'}}>
                    <HashLoader 
                      color={'#0A81C4'}
                      loading={true}
                      size={50}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </Box>
                </>
                }  
                {!isMobile &&
                <Box textAlign="center" color={"red"} style={{marginTop: 30}}>
                        Para ser redirecionado ao aplicativo da Instituição Financeira, você precisa acessar através de um dispositivo móvel (Android ou iOS).
                </Box>}         
              </Grid>
            </Box>
        </div>
      </div>
  );
}


function makeStyles() {
  return createStyles({
    backgroundImage: {
      backgroundImage: `url(${loginBackground})`,
      display: "flex",
      alignItems: 'center',
      justifyContent: 'center',
    },
    containerWelcome: {
      backgroundColor: "#FFFFFF",
    },

    containerContent: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 100,
    },
    containerVariant: {
      marginTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
    },
    containerVariantTop: {
      marginTop: 10,
      paddingLeft: 40,
      paddingRight: 40,
    },
    containerVariantspacing: {
      marginTop: 30,
      paddingLeft: 40,
      paddingRight: 40,
    },
    variantH1: {
      color: "#0A81C4",
      fontSize: 20,
      fontFamily: fonts.semiBold,
    },
    variantTitle: {
      color: "#606060",
      fontSize: 16,
      fontFamily: fonts.medium,
    },
    variantSubTitle: {
      fontSize: 14,
      fontFamily: fonts.semiBold,
      color: "#0A81C4",
    },
    variantSubTitleLink: {
      fontSize: 16,
      color: "#606060",
      fontFamily: fonts.regular,
    },
    variantTitleCase: {
      color: "#0A81C4",
      fontSize: 16,
      fontFamily: fonts.bold,
    },

    enter: {
      fontFamily: fonts.medium,
      fontSize: 14,
      color: "white",
      marginTop: 20,
    },
    titleRegister: {
      fontFamily: fonts.medium,
      fontSize: 12,
      color: "#888888",
      marginRight: 5,
      marginTop: 15,
    },
    subtitleRegister: {
      fontFamily: fonts.medium,
      fontSize: 14,
      color: "#55A695",
      marginRight: 5,
    },
    button: {
      width: 180,
      height: 64,
    },
  });
}
