import { BrowserRouter as Router } from 'react-router-dom';

import history from './services/history'
import RoutesApp from './services/routes';
import "./App.css";

function App() {
  return (
    <Router history={history}>
      <RoutesApp />
    </Router>
  );
}

export default App;
