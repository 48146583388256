import React from "react";
import { createStyles } from "@mui/material";


import { RecepcaoOpenFinance } from "../../components";
import images from "../../config/images";


export function LoginOpenFinance() {

  const styles = makeStyles();

  return (
    <div style={styles.backgroundImage}>
      <RecepcaoOpenFinance />
    </div>
  );
}

function makeStyles() {
  return createStyles({
    backgroundImage: {
      backgroundImage: `url(${images.loginBackground})`,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
}
