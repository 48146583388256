import { BrowserRouter as Router, Routes, Route, redirect } from 'react-router-dom';

import { LoginOpenFinance } from '../pages/LoginOpenFinance';
import AASA from '../pages/apple-app-site-association.json'


export default function RoutesApp() {
  
    return (
        <Routes>
            <Route 
                exact
                path='/'
                element={<LoginOpenFinance />}
            />
            <Route 
                exact
                path='/apple-app-site-association'
                element={<AASA />}
            />
        </Routes>      
    )
}